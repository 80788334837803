<template>
  <div class="teamDetail">
    <div id="title">
      <div class="teamPreHeader">
        <IconButton :button-function="()=>$router.back()" button-type="fluid">
        <span class="material-icons">
          chevron_left
        </span>
        </IconButton>
        <p>Team</p>
      </div>


      <div class="elementAndIconRow">
        <h1 class="element">{{ team?.team_name }}</h1>
        <div class="teamLink" v-if="this.currentUserIsCaptain">
            <IconButton :button-function="this.createInviteLink" button-type="fluid">
              <span class="material-icons" style="font-size: 32px">
              link
              </span>
            </IconButton>
        </div>
      </div>

      <div class="buttonRow">
        <PlainButton class="element" :button-function="leaveTeamWarning" button-type="cancel" scale-by-child is-small is-slim>
          <div style="display: flex;justify-content: center; align-items: center; line-height: 1; gap:4px">
            <span class="material-icons">logout</span>
            Team verlassen
          </div>
        </PlainButton>
        <div v-if="this.currentUserIsCaptain">
          <IconButton :button-function="this.renameTeam" button-type="fluid">
            <span class="material-icons">
              edit
            </span>
          </IconButton>

        </div>
      </div>

    </div>
    <div id="details">
      <div>
        <DetailElement icon="groups" title="Team Mates:"
                       :description="team?.team_mates?.length.toString().concat(' ',team?.team_mates?.length>1?'Mates':'Mate')"></DetailElement>
        <DetailElement icon="sports_esports" title="Turnier:" :description="team?.tournament?.game.full_name"/>
        <DetailElement icon="event" title="Start:"
                       :description="formatDate(new Date(team?.tournament?.datetime))"></DetailElement>
      </div>

    </div>


    <div class="tableContainer">
      <h2 class="teamHeader">Mitglieder</h2>
      <div class="teamTable">
        <TeamMember v-for="team_mate in this.team?.team_mates" v-bind:key="team_mate.user_id" :team_mate="team_mate"
                    :captain_id="this.team?.team_captain_id" :promote="promoteToCaptain" :kick-player="kickTeamMemberWarning" :registration-phase="team.tournament.current_registration_phase"></TeamMember>
      </div>
    </div>

    <transition name="dialogBackground">
      <div class="dialogBackground"
           v-if="playerToPromote||playerToKick||leaveWarningDialogVisible||renameTeamDialogVisible"
           @click="()=>{playerToPromote=false;playerToKick=false;leaveWarningDialogVisible=false;renameTeamDialogVisible=false}"></div>
    </transition>
    <transition name="dialog">
      <ErrorDialog button-text="Verlassen" :is-warning="true" :show-close-button="true" dialog-title="Bist du sicher?"
                   :close-function="()=>{leaveWarningDialogVisible=false}" :confirm-function="this.leaveTeam"
                   v-if="leaveWarningDialogVisible">Bist du sicher, dass du dein Team verlassen willst?
      </ErrorDialog>
    </transition>
    <transition name="dialog">
      <ErrorDialog button-text="Promote" :is-warning="true" :show-close-button="true" dialog-title="Bist du sicher?"
                   :close-function="()=>{playerToPromote=null}" :confirm-function="() => {this.promotePlayerToCaptain(this.team.team_id, playerToPromote); this.playerToPromote=null}"
                   v-if="playerToPromote">Bist du sicher, dass du einen anderen Spieler promoten willst?
      </ErrorDialog>
    </transition>
    <transition name="dialog">
      <ErrorDialog button-text="Kick" :is-warning="true" :show-close-button="true" dialog-title="Bist du sicher?"
                   :close-function="()=>{playerToKick=null}" :confirm-function="kickTeamMember"
                   v-if="playerToKick">Bist du sicher, dass du deinen Mitspieler kicken willst?
      </ErrorDialog>
    </transition>
    <transition name="dialog">
      <RenameTeamDialog v-if="renameTeamDialogVisible" :team_id="team.team_id" :change-team-name="changeTeamName"
                     :close-function="()=>{renameTeamDialogVisible=false}"></RenameTeamDialog>
    </transition>

    <transition name="dialog">
      <ErrorDialog button-text="OK" :is-warning="false" :show-close-button="false"
                   dialog-title="Spieler-Anmeldung geschlossen"
                   :confirm-function="()=>{playerParticipationClosedDialogVisible=false}"
                   v-if="playerParticipationClosedDialogVisible">Die Anmeldung für dieses Turnier ist zurzeit geschlossen. Es
        können keine Teamänderungen gemacht werden.
      </ErrorDialog>
    </transition>
    <transition name="dialog">
      <ErrorDialog button-text="OK" :is-warning="false" :show-close-button="false"
                   dialog-title="Turnier hat begonnen"
                   :confirm-function="()=>{teamEditingDialogVisible=false}"
                   v-if="teamEditingDialogVisible">Das Turnier hat bereits begonnen und es können keine Änderungen mehr vorgenommen werden
      </ErrorDialog>
    </transition>
  </div>
</template>

<script>
import TeamMember from "@/components/Team/TeamMember";
import DetailElement from "@/components/Tournaments/DetailElement";
import PlainButton from "@/components/Buttons/PlainButton";
import {teamService} from "@/services";
import ErrorDialog from "@/components/Dialogs/ErrorDialog";
import RenameTeamDialog from "../Dialogs/RenameTeamDialog";
import store from "../../store";
import IconButton from "@/components/Buttons/IconButton";

export default {
  name: "TeamDetail",

  props: {
    team: Object,
    promotePlayerToCaptain: Function,
    kickPlayer: Function,
    createJoinLink: Function,
    changeTeamName: Function,
  },

  data() {
    return {
      leaveWarningDialogVisible: false,
      playerToPromote: null,
      playerToKick: null,

      renameTeamDialogVisible: false,

      playerParticipationClosedDialogVisible: false,
      teamEditingDialogVisible: false,
    }
  },

  components: {
    IconButton,
    RenameTeamDialog,
    PlainButton,
    TeamMember,
    DetailElement,
    ErrorDialog,
  },
  methods: {
    allowedToPerformAction(isEdit) {
      return (isEdit || this.team.tournament.current_registration_phase <= 2) && this.team.tournament.current_registration_phase <= 3
    },
    showEventDialog(isEdit, isLink, isLeave) {
      this.playerParticipationClosedDialogVisible = false;
      this.teamEditingDialogVisible = false;

      if(isEdit) {
        this.teamEditingDialogVisible = true;
      } else if(isLink || isLeave) {
        this.playerParticipationClosedDialogVisible = true;
      }

    },
    formatDate(date) {
      return date.getDate() + "." + (date.getMonth() + 1) + "." + date.getUTCFullYear() + " - " + ((date.getHours()) % 24) + ":" + ('0' + date.getMinutes()).slice(-2)
    },
    leaveTeamWarning() {
      if(this.allowedToPerformAction(false)) {
        this.leaveTeamID = this.team.team_id;
        this.leaveWarningDialogVisible = true;
      } else {
        this.showEventDialog(false, false, true);
      }

    },
    leaveTeam() {
      teamService.leaveTeam(this.leaveTeamID).then(() => {
            this.leaveWarningDialogVisible = false;
            this.leaveTeamID = 0;
          }
      )
      this.$router.back();
    },
    kickTeamMemberWarning(userid) {
      if(this.allowedToPerformAction(false)) {
        this.playerToKick = userid;
      } else {
        this.showEventDialog(false, false, true);
      }
    },
    kickTeamMember() {
      this.kickPlayer(this.team.team_id, this.playerToKick);
      this.playerToKick=null
    },
    renameTeam() {
      if(this.allowedToPerformAction(true)) {
        this.renameTeamDialogVisible = true
      } else {
        this.showEventDialog(true, false, false);
      }
    },
    promoteToCaptain(userId) {
      if(this.allowedToPerformAction(true)) {
        this.playerToPromote = userId
      } else {
        this.showEventDialog(true, false, false);
      }
    },
    createInviteLink() {
      if(this.allowedToPerformAction(false)) {
        this.createJoinLink(this.team.team_id)
      } else {
        this.showEventDialog(false, true, false);
      }
    }
  },
  computed: {
    currentUserIsCaptain() {
      return store.state.account.user.userdata.user_id === this.team.team_captain_id;
    },
  }
}
</script>

<style lang="scss" scoped>

@import "../../styles/themes.scss";
.error-show-enter-active, .error-show-leave-active {
  transition: all 0.25s;
}
.error-show-enter-from,
.error-show-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
.dialogBackground-enter-active, .dialogBackground-leave-active {
  transition: opacity 0.25s;
}
.dialogBackground-enter-from, .dialogBackground-leave-to {
  opacity: 0;
}
.dialog-enter-active, .dialog-leave-active {
  transition: 0.25s ease;
}
.dialog-enter-to, .dialog-leave-from {
  transform-origin: center;
  transform: translateX(-50%) translateY(-50%) scale(1);
}
.dialog-enter-from, .dialog-leave-to {
  transform-origin: center;
  transform: translateX(-50%) translateY(-50%) scale(0);
}
.dialogBackground {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  background-color: hsla(0, 0%, 0%, 0.5);
  backdrop-filter: blur(50px);
  width: 100%;
  height: 100%;
}

.elementAndIconRow {
  flex: 1;
  min-width: 0;

  width: 100%;
  display: flex;
  align-items: center;
  gap:24px;
  justify-content: flex-start;
}

.teamDetail {
  padding: 0 256px;
  display: grid;
  grid-template-columns: auto 324px;
  grid-template-rows: auto auto;
  grid-template-areas:
  "title details"
  "table table";
  grid-gap: 48px;
  overflow: hidden;
}

.tableContainer {
  grid-area: table;
  width: 100%;
  height: 100%;
  position: relative;
  margin-bottom: auto;
  flex-direction: column;
}

.teamTable {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 16px;
  gap: 24px;
}

.teamHeader {
  text-align: left;
  font-size: 2.5em;
  font-weight: 500;
  margin-top:0;
  @include theme {
    color: theme-get('listheader');
  }
}

#title {
  grid-area: title;
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-width: 0;
  .teamPreHeader{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:16px;
  };
  .buttonRow{
    width: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
  }
  h1 {
    font-weight: 600;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;

    @include theme {
      color: theme-get('header-1');
    }
    font-size: 4em;
    margin-top: 0;
    margin-bottom: 0;
  }
  p {
    @include theme {
      color: theme-get('text-2');
    }
    margin: 0;
    font-size: 1.5em;
    line-height: 1;
    text-align: left;

  }

  margin-bottom: 32px;
}

#details {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  grid-area: details;
}

.leaveTeam {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  gap: 8px;
  text-transform: uppercase;
  letter-spacing: 4px;
}

@media screen and (max-width: 1368px) {
  .teamDetail {
    padding: 0 128px;
  }
  #details {
    display: flex;

    flex-direction: column;
    justify-content: center;
    grid-area: details;
  }
}

@media screen and (max-width: 1024px) {

  .teamTable {
    grid-template-columns: minmax(0,1fr);
    align-items: stretch;
  }

  .teamDetail {
    grid-template-areas:
        "title"
        "details"
        "table";
    grid-template-columns: minmax(0,1fr);

  }

  .elementAndIconRow{
    .element{
      width: 100%;

    }
  }


}

@media screen and (max-width: 768px) {


  #title{
    gap:24px;

  }
  #details{
    align-items: center;
    width: auto;
  }
  .teamDetail{
    overflow: hidden;
    .elementAndIconRow{
      display: flex;

      flex-direction: column;
      gap:8px;
      min-width: 0;
      .element{
        text-align: center;
        width: auto;
        min-width: 0;
      }
    }
  }
  .back-arrow {
    width: 100%;
    justify-content: flex-start;
  }

  #title {
    min-width: 0;
    align-items: center;
  }

}

@media screen and (max-width: 512px) {
  .teamDetail{
    padding: 32px;
  }
  #title {
    h1 {
      font-size: 3em;
      margin-bottom: 8px;
    }
  }
  .teamHeader{
    font-size: 1.5em;
  }
}
</style>
