<template>
  <div class="teams">

    <FullscreenLoading v-if="alertType==='alert-loading'">
    </FullscreenLoading>


    <TeamDetail v-if="this.team"
        :change-team-name="changeTeamName"
        :team="team"
        :promote-player-to-captain="promotePlayerToCaptain"
        :kick-player="kickPlayer"
        :createJoinLink="createTeamLink">
    </TeamDetail>


    <transition name="error-show">
      <SuccessPop :error-text="'Link copied to clipboard'" v-if="alertType==='alert-success'"></SuccessPop>
    </transition>
    <transition name="error-show">
      <ErrorPop :error-text="alertMessage" v-if="alertType==='alert-danger'"></ErrorPop>
    </transition>
  </div>
</template>

<script>
import FullscreenLoading from "../../components/Indicators/Loading/FullscreenLoading";
import {teamService} from "../../services";
import TeamDetail from "../../components/Team/TeamDetail";
import {mapState} from "vuex";
import SuccessPop from "../../components/Popups/SuccessPop";
import {service} from "../../services/service";
import ErrorPop from "../../components/Popups/ErrorPop";

export default {
  name: "Teams",
  inheritAttrs: false,

  components: {
    ErrorPop,
    TeamDetail,
    FullscreenLoading,
    SuccessPop,
  },
  data() {
    return {
      team: null,
    }
  },
  computed: {
    ...mapState('alert', {
          alertMessage: 'message',
          alertType: 'type'
        }
    ),
    active() {
      return parseInt(this.$route.params.tournament_id.toString()) ?? 1;
    }
  },
  created() {
    this.getTeamsFromUser();
  },
  methods: {
    getTeamsFromUser() {
      /*TODO: Replace with FindbyID*/
      teamService.fetchTournaments().then(gameEvent => {
        let tournaments = gameEvent?.tournaments ?? []

        if (tournaments.length > 0) {
          teamService.fetchUserTeams().then(teams => {
            this.team = teams.find(t => t.tournament_id === this.active && t.tournament.event_id === tournaments[0].event_id);

            if (this.team == null) {
              this.$router.back();
            } else {
              this.sortTeamMates();
            }
          });
        } else {
          this.$router.back();
        }
      },);
    },
    promotePlayerToCaptain(teamId, userId) {
      teamService.promoteToCaptain(teamId, userId);
      this.team.team_captain_id = userId;
      this.sortTeamMates();
    },
    kickPlayer(teamId, userId) {
      teamService.kickPlayer(teamId, userId);
      this.team.team_mates = this.team.team_mates.filter(m => m.user_id !== userId)
    },
    sortTeamMates() {
      let index = this.team.team_mates.findIndex(m => m.user_id === this.team.team_captain_id);
      let tmp = this.team.team_mates[0];
      this.team.team_mates[0] = this.team.team_mates[index];
      this.team.team_mates[index] = tmp;
    },
    createTeamLink(teamId) {
      teamService.createTeamLink(teamId).then(link => {
        navigator.clipboard.writeText(link)
      })
    },
    changeTeamName(name, closeFunction) {
      teamService.renameTeam(this.team.team_id, {
        'team_name': name,
      }).then(
          team => {
            service.clearAlerts();
            this.team.team_name = team.team_name;
            closeFunction();
          },
          error => {
            service.setError(error[0].msg);
          }
      )
    }

  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/themes";

.teams {
  padding-top: 130px;
  padding-bottom: 64px;
  min-height: 100%;
  width: 100%;
  @include theme {
    background-color: theme-get('background-color-2');
  }
}

</style>
