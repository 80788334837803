<template>
  <div class="renameTeamDialog">
    <h1>Team Namen ändern</h1>
    <TextInput :input-title="field.label" v-model:input-text="field.text"
               v-for="field in Object.values(fields).filter(value=>(value.id!=='password'))"
               :inputPlaceholder="field.placeholder" :textFieldType="field.type"
               :validationFunction="field.validFunction" :doValidation="field.useValidation" :key="field.id"
               :ref="field.id"></TextInput>

    <div class="actions">
      <PlainButton button-text="Abbrechen" :button-function="closeFunction" button-type="fluid">
        Abbrechen
      </PlainButton>
      <PlainButton :button-function="renameTeam">
        Bestätigen
      </PlainButton>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/Inputs/TextInput";
import {mapActions} from "vuex";
import PlainButton from "@/components/Buttons/PlainButton";

export default {
  name: "RenameTeamDialog",
  components: {PlainButton, TextInput},
  props: {
    team_id: Number,
    closeFunction: Function,
    changeTeamName: Function,
  },
  data() {
    return {
      isPasswordProtected: false,
      fields: {
        teamname: {
          label: 'Team Name',
          text: '',
          id: 'teamname',
          placeholder: 'DieFischis',
          type: 'text',
          validFunction: function (text) {
            if (text.length === 0) {
              return {
                isInvalid: true,
                errorText: 'Teamname eingeben'
              }
            } else if (text.length > 32) {
              return {
                isInvalid: true,
                errorText: 'Teamname darf nicht länger als 32 Zeichen lang sein'
              }

            } else {
              return {
                isInvalid: false,
                errorText: ''
              }
            }
          },
          useValidation: true
        },

      }
    }
  },

  computed: {
    team_name() {
      return Object.values(this.fields).find(value => value.id === 'teamname').text
    },
  },
  methods: {
    ...mapActions('alert', ["error"]),

    renameTeam() {
      this.changeTeamName(this.team_name, this.closeFunction);
    },

    validateTexts(context) {
      let isValid = true;

      Object.values(context.fields).forEach(field => {
        if (!(!context.isPasswordProtected && field.id === "password")) {

          context.$refs[field.id].validate(field.text)
          isValid = !context.$refs[field.id].isInvalid && isValid;
        }

      });
      return isValid;
    }
  },

}
</script>

<style lang="scss" scoped>
@import "src/styles/themes.scss";

.renameTeamDialog {
  box-shadow: 0 5px 20px rgba(0, 0%, 0%, 0.15);
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 32px;
  align-items: flex-start;
  z-index: 101;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: auto;

  @include theme() {
    background-color: theme-get('background-color-2');
  }

  border-radius: 16px;

  h1 {
    @include theme() {
      color: theme-get('header-1');
    }

    margin-top: 0;
    line-height: 1.05;
    font-size: 3em;
    font-weight: 600;
  }

  .actions {
    width: 100%;
    margin-top: 48px;
    display: flex;
    gap: 16px;
    justify-content: space-evenly;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .renameTeamDialog {
    align-items: center;

    h1 {
      font-size: 2em;
    }
  }
  .renameTeamDialog {
    .actions {
      margin-top: 16px;
      flex-direction: column-reverse;
    }
  }


}
</style>
